import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import {
  Done,
  EmojiEvents,
  EventBusy,
  InfoOutlined,
  Pending,
  ThumbDown,
} from "@mui/icons-material";
import {
  METODOS_PAGO,
  STATUS_COMP,
  STATUS_TORNEO,
} from "../../../../Config/constantes";
import { formatoMexico, getMomioFormatTextv2, isMobile } from "../../../../Config/reuserFunctions";
import { MainContext } from "../../../../Context/MainCtx";
import { useMutation, useQuery } from "@apollo/client";
import {
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DialogSesion from "./DialogSesion";
import { FaCoins } from "react-icons/fa";
import { CreateApuestaSeller } from "../../../Home/Torneos/graphql/mutations";
import { GetMomioCalculateV2 } from "../../../Home/Torneos/graphql/queries";
import Ticket from "./Ticket";
import { io } from "socket.io-client";
import FormatoCuotas from "../../../../Components/FormatoCutoas";

export default function CrearApuesta({ data, torneo, charreada, terminado }) {
  const { ganador, cerrado, pendiente } = STATUS_COMP;
  const { pausado } = STATUS_TORNEO;

  const CERRADO = torneo.status === pausado || data.status === cerrado;
  const TERMINADO = CERRADO && !terminado;
  const PERDEDOR = CERRADO && terminado;
  const PAUSADO = data.status === pendiente;
  const GANADOR = data.status === ganador;

  if (GANADOR) {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Chip
          color="success"
          icon={<EmojiEvents />}
          size="small"
          label="Ganador"
          sx={{ fontSize: { xs: 11, md: 13 } }}
        />
      </Box>
    );
  }
  if (data.noSePresento) {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Chip
          icon={<EventBusy />}
          size="small"
          label={"No se presentó"}
          sx={{ fontSize: { xs: 11, md: 13 } }}
        />
      </Box>
    );
  }
  if (TERMINADO) {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Chip
          icon={<Pending />}
          size="small"
          label={isMobile ? "En espera" : "En espera de resultados"}
          sx={{ fontSize: { xs: 11, md: 13 } }}
        />
      </Box>
    );
  }
  if (PERDEDOR) {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Chip
          color="error"
          icon={<ThumbDown />}
          size="small"
          label={"Perdedor"}
          sx={{ fontSize: { xs: 11, md: 13 } }}
        />
      </Box>
    );
  }
  if (PAUSADO) {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Chip
          icon={<Pending />}
          size="small"
          label={"Por confirmar"}
          sx={{ fontSize: { xs: 11, md: 13 } }}
        />
      </Box>
    );
  }

  return (
    <RenderApuestaAction data={data} charreada={charreada} torneo={torneo} />
  );
}

const RenderApuestaAction = ({
  data,
  torneo,
  charreada,
}) => {
  const {
    snackMessage,
    tokenSesion,
    tokenDecoded,
    setErrors,
  } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [ticket, setTicket] = React.useState(null);
  const [metodoPago, setMetodoPago] = React.useState(METODOS_PAGO.efectivo);
  const [ganancia, setGanancia] = React.useState(0);
  const [createApuestaSeller] = useMutation(CreateApuestaSeller);
  const suerte = data.suerteData?.suerte;
  const isTeam = suerte.toLowerCase() === "equipo";

  const momioQuery = useQuery(GetMomioCalculateV2, {
    variables: {
      input: {
        idCompetencia: data._id,
        torneo: data.torneo,
        equipo: data.equipo,
        charreada: data.charreada,
        charro: data.charro,
        suerte: data.suerte,
        multiplicador: data.multiplicador,
        momioMin: data.momioMin,
        momioMax: data.momioMax,
      },
    },
    //fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("updatedMomio", () => momioQuery?.refetch());
    return () => socket.disconnect();
  }, [momioQuery]);

  React.useEffect(() => {
    if (momioQuery?.error)
      setErrors({ ...momioQuery?.error, funcion: "getCalcCompetencia" });
  }, [momioQuery, setErrors]);

  if (momioQuery?.loading) {
    return (
      <Button
        disabled
        disableElevation
        variant="contained"
        className="fontAnton"
        startIcon={<CircularProgress size={18} color="inherit" />}
        sx={{ fontSize: { xs: 11, md: 14 } }}
      >
        Juega
      </Button>
    );
  }
  if (momioQuery?.error) {
    return (
      <Button
        disabled
        disableElevation
        variant="contained"
        className="fontAnton"
        startIcon={<InfoOutlined color="disabled" />}
        sx={{ fontSize: { xs: 11, md: 14 } }}
      >
        Juega
      </Button>
    );
  }

  //const momio = momioQuery?.data?.getCalcCompetencia?.momio || 0;
  const momio = momioQuery?.data?.getMomioCalculateV2?.momioDecimal || 0;
  const americano = momioQuery?.data?.getMomioCalculateV2?.momioAmericano || 0;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValue(0);
    setGanancia(0);
  };

  const handleSelectToros = (value) => {
    setValue(parseFloat(value || 0));
    setGanancia(value * parseFloat(momio) + value);
  };

  const crearApuestaBD = async () => {
    try {
      if (!value) {
        snackMessage({
          message: "Seleccione un monto",
          variant: "error",
        });
        return;
      }
      if (!tokenDecoded) {
        snackMessage({
          message: "No hay sesion",
          variant: "error",
        });
        return;
      }
      setLoading(true);
      const response = await createApuestaSeller({
        variables: {
          input: {
            idCompetencia: data._id,
            torneo: torneo._id,
            charreada: charreada._id,
            equipo: data.equipo,
            suerte: data.suerte,
            charro: data.charro,
            usuario: tokenDecoded._id,
            apuesta: parseFloat(value),
            totalGanar: parseFloat(ganancia),
            momio,
          },
          metodoPago,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      const res = response?.data?.createApuestaSeller;
      setTicket({
        isTeam,
        torneo: torneo.nombre,
        charreada: charreada.nombre,
        equipo: data.equipoData.nombre,
        suerte: data.suerteData.suerte,
        charro: data.charroData.nombre,
        apuesta: res.apuesta,
        totalGanar: res.totalGanar,
        momio: res.momio,
        fecha: res.fecha,
        ticket: res.ticket,
        formaPago: metodoPago,
      });
      setLoading(false);
      //handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
      setErrors({ ...error, funcion: "createApuestaSeller" });
    }
  };

  const montos = [50, 100, 200, 300, 500, 1000];
  const {paypal, ...metodo} = METODOS_PAGO;

  return (
    <React.Fragment>
      {!tokenDecoded ? (
        <DialogSesion
          momioQuery={momioQuery}
          suerte={data.suerteData?.suerte}
          momio={momio}
          americano={americano}
        />
      ) : (
        <Button
          disabled={momioQuery?.loading || momioQuery?.error}
          disableElevation
          variant="contained"
          className="fontAnton"
          onClick={handleClickOpen}
          startIcon={getMomioFormatTextv2(momio, americano, FormatoCuotas)}
          sx={{ fontSize: { xs: 11, md: 14 } }}
        >
          Juega
        </Button>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle component="div">
          <Typography>{torneo.nombre}</Typography>
          <Typography variant="body1">{charreada.nombre}</Typography>
        </DialogTitle>
        <DialogContent>
          <Ticket
            ticket={ticket}
            setTicket={setTicket}
            handleCloseExt={handleClose}
          />
          <Grid container spacing={1}>
            {montos.map((monto) => (
              <Grid key={monto} item xs={4} md={2}>
                <Button
                  fullWidth
                  variant={value === monto ? "contained" : "outlined"}
                  disableElevation
                  onClick={() => handleSelectToros(monto)}
                  startIcon={<FaCoins style={{ fontSize: 13 }} />}
                >
                  {monto}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: 2 }}>
            {/* <TextField
              size="small"
              fullWidth
              variant="outlined"
              type="number"
              value={value}
              onChange={(e) => handleSelectToros(e.target.value)}
            /> */}
            <FormControl fullWidth size="small" margin="dense" required>
              <InputLabel id="metodos-select">Método de pago</InputLabel>
              <Select
                labelId="metodos-select"
                value={metodoPago}
                label="Método de pago"
                onChange={(e) => setMetodoPago(e.target.value)}
              >
                {Object.keys(metodo).map((res, index) => (
                  <MenuItem key={index} value={metodo[res]}>
                    {metodo[res]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              <Typography>
                <b>Suerte:</b>
              </Typography>
              <Typography>
                <b>{data.suerteData.suerte}</b>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              {isTeam ? (
                <Typography>
                  <b>{data.equipoData.nombre}</b>
                </Typography>
              ) : (
                <Typography>
                  <b>{data.charroData.nombre}</b>
                </Typography>
              )}
            </Box>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
              <Typography>Momio:</Typography>
              <Typography>
                <b>{getMomioFormatTextv2(momio, americano, FormatoCuotas)}</b>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
              <Typography>En juego:</Typography>
              <Typography
                sx={{ gap: 0.5, display: "flex", alignItems: "center" }}
              >
                <span>
                  <FaCoins style={{ fontSize: 13 }} />
                </span>
                <b>{formatoMexico(value)}</b>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
              <Typography>Posible recompensa:</Typography>
              <Typography
                sx={{ gap: 0.5, display: "flex", alignItems: "center" }}
              >
                <span>
                  <FaCoins style={{ fontSize: 13 }} />
                </span>
                <b>{formatoMexico(ganancia)}</b>
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <Box
          sx={{
            p: 1,
            gap: 1,
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <Button
            fullWidth
            disableElevation
            color="inherit"
            variant="contained"
            onClick={handleClose}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={crearApuestaBD}
            variant="contained"
            disableElevation
            fullWidth
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Done />
              )
            }
            disabled={loading || !parseFloat(value)}
          >
            Jugar
          </Button>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};
