import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Link } from "react-router-dom";
import { getMomioFormatTextv2 } from "../../../../Config/reuserFunctions";
import { MainContext } from "../../../../Context/MainCtx";

export default function DialogSesion({ momioQuery, americano, momio }) {
  const [open, setOpen] = React.useState(false);
  const { formatoCuota } = React.useContext(MainContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        disabled={momioQuery.loading || momioQuery.error}
        disableElevation
        variant="contained"
        className="fontAnton"
        onClick={handleClickOpen}
        startIcon={getMomioFormatTextv2(momio, americano, formatoCuota)}
        sx={{ fontSize: { xs: 11, md: 14 } }}
      >
        Juega
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ textAlign: "center" }}>
          Inicia sesión para continuar
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Inicia sesión en tu cuenta o regístrate para empezar a jugar.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleClose}
            variant="contained"
            color="inherit"
            disableElevation
            size="small"
            sx={{ textTransform: "none" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disableElevation
            size="small"
            sx={{ textTransform: "none" }}
            component={Link}
            to="/signup"
          >
            Registrarme
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            sx={{ textTransform: "none" }}
            component={Link}
            to="/signin"
          >
            Iniciar sesión
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
