import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CustomButton } from "../CustomMuiComponents";
import { Done, Close } from "@mui/icons-material";
import { MainContext } from "../../Context/MainCtx";
import { UploadFileExcel } from "./mutations";
import { useMutation } from "@apollo/client";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UploadToDB({ handleClose, file, refetchModel, model, onlyUpload, factorCuota = 3 }) {
  const [open, setOpen] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [uploadFileExcel] = useMutation(UploadFileExcel);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleUpload = async (action) => {
    try {
      if (!file || loading) return;
      setLoading(true);
      handleCloseDialog();
      const response = await uploadFileExcel({
        variables: {
          input: { file, model, action, factorCuota },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.uploadFileExcel.message,
        variant: "success",
      });
      if (refetchModel) refetchModel();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <>
      <CustomButton
        variant="contained"
        size="small"
        color="info"
        disableElevation
        onClick={handleClickOpen}
        disabled={!file || loading}
        startIcon={
          loading ? <CircularProgress color="inherit" size={20} /> : <Done />
        }
      >
        Guardar
      </CustomButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleUpload}
      >
        <DialogTitle component="div">
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Acción requerida
            </Typography>
            <CustomButton
              variant="contained"
              size="small"
              color="inherit"
              disableElevation
              onClick={handleCloseDialog}
              startIcon={<Close />}
            >
              Cerrar
            </CustomButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {onlyUpload ? (
            <Alert severity="info">
              <AlertTitle>
                <b>Cargar Xls </b>
              </AlertTitle>
              Se insertaran los siguientes datos a la base de datos, si hay
              datos existentes, estos serán actualizados
            </Alert>
          ) : (
            <Alert severity="info">
              <AlertTitle>
                <b>Insertar</b>
              </AlertTitle>
              Se insertaran los siguientes datos a la base de datos sin afectar
              los datos anteriores
              <Box mb={1} />
              <AlertTitle>
                <b>Eliminar e insertar</b>
              </AlertTitle>
              Se ELIMINARAN los datos existentes y se insertaran los nuevos
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          {!onlyUpload ? (
            <CustomButton
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => handleUpload("deleteAndInsert")}
            >
              Eliminar e Insertar
            </CustomButton>
          ) : null}
          <CustomButton
            variant="contained"
            disableElevation
            color="primary"
            onClick={() => handleUpload("insert")}
          >
            {onlyUpload ? "Cargar xlsx" : "Insertar Nuevos"}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
