import {
  amber,
  blue,
  blueGrey,
  deepOrange,
  green,
  lightBlue,
  lightGreen,
  yellow,
} from "@mui/material/colors";
import moment from "moment-timezone";
import {
  STATUS_APUESTA,
  STATUS_COMP,
  STATUS_MOVIMIENTO,
  STATUS_TORNEO,
} from "./constantes";
import {
  Cancel,
  CheckCircle,
  DoDisturbOn,
  WatchLater,
} from "@mui/icons-material";

import 'moment/locale/es'; 
moment.locale('es');

export const generateCode = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const generateCodeNumerico = (length = 10) => {
  var result = "";
  var characters = "0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const formatoFechaHora = (fecha) => {
  if (!fecha) return null;
  return moment(fecha)
    .tz("America/Mexico_City")
    .format("DD MMMM YYYY, h:mm:ss a");
};

export const formatoFechaCorta = (fecha) => {
  if (!fecha) return null;
  return moment(fecha).tz("America/Mexico_City").format("ll");
};
export const formatoMexico = (number, decimal = 2) => {
  if (!number) return 0;
  let nueva = parseFloat(number).toFixed(decimal);
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = "$1,";
  return nueva.toString().replace(exp, rep);
};
export const getContrastColor = (bgColor = "#ffffff") => {
  if (!bgColor) return null;
  // Convertir el color de fondo a RGB
  const r = parseInt(bgColor.slice(1, 3), 16);
  const g = parseInt(bgColor.slice(3, 5), 16);
  const b = parseInt(bgColor.slice(5, 7), 16);

  // Calcular el valor de luminosidad (L)
  //const L = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  const L = 0.1412 * r + 0.5652 * g + 0.0722 * b;

  // Elegir el color de texto en función del contraste
  const textColor = L > 128 ? "#000000" : "#ffffff";
  return textColor;
};

export function getMomio(valor) {
  // Asegúrate de que el valor sea una cadena
  const stringValue = valor.toString();
  
  // Extrae el signo y el valor numérico
  const signo = stringValue.startsWith('-') ? '-' : '+';
  const momio = parseFloat(stringValue.replace(/[+-]/, ''));

  return { momio, signo };
}

export function getMomioConversor(momio, format) {
  const momioDecimal = momio + 1
  if(format === "Decimal") return formatoMexico(momio);
  if (momioDecimal > 2.00) {
      // Momio favorable
      return `+${Math.round((momioDecimal - 1) * 100)}`;
  } else if (momioDecimal < 2.00) {
      // Momio desfavorable
      return `${Math.round(-100 / (momioDecimal - 1))}`;
  } else {
      // Momio decimal igual a 2.00
      return "+100";
  }
}

export function getMomioFormatTextv2(decimal, americano, format) {
  if(format === "Americano") return `${americano}`;
  return formatoMexico(decimal);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const getColorStatusProm = (status) => {
  const { pausado, activo, cerrado, ganador } = STATUS_COMP;
  switch (status) {
    case activo:
      return green[100];
    case pausado:
      return amber[200];
    case cerrado:
      return blue[100];
    case ganador:
      return green[300];
    default:
      return blueGrey[100];
  }
};
export const getColorStatusTorneo = (status) => {
  const { pausado, activo, cerrado } = STATUS_TORNEO;
  switch (status) {
    case activo:
      return green[100];
    case pausado:
      return amber[200];
    case cerrado:
      return blue[100];
    default:
      return blueGrey[100];
  }
};
export const getColorStatusApuesta = (status) => {
  const { ganada, pendiente, cancelada, perdida } = STATUS_APUESTA;
  switch (status) {
    case ganada:
      return lightGreen[300];
    case pendiente:
      return amber[200];
    case cancelada:
      return deepOrange[50];
    case perdida:
      return deepOrange[300];
    default:
      return lightBlue[300];
  }
};

export const GetIconMovimiento = ({ status }) => {
  const { realizado, cancelado, fallido } = STATUS_MOVIMIENTO;
  switch (status) {
    case realizado:
      return <CheckCircle fontSize="small" color="success" />;
    case cancelado:
      return <DoDisturbOn fontSize="small" color="warning" />;
    case fallido:
      return <Cancel fontSize="small" color="error" />;
    default:
      return <WatchLater fontSize="small" color="action" />;
  }
};

export const getColorTipoEvento = (tipo, hover) => {
  switch (tipo) {
    case "ORO":
      return hover ? yellow[800] : yellow[600];
    case "PLATA":
      return hover ? blueGrey[400] : blueGrey[200];
    default:
      return hover ? "#be6127" : "#da8a67";
  }
};

export function filtrarPalabra(cadena, palabra) {
  if (cadena.includes("EMPATE")) {
    // Regex para eliminar todas las palabras excepto la palabra especificada
    let regex = new RegExp(`\\b(?!${palabra}\\b)\\w+\\b`, "g");
    let nuevaCadena = cadena.replace(regex, "");
    // Eliminar caracteres que no sean letras o espacios
    nuevaCadena = nuevaCadena.replace(/[^A-Z\s]/gi, "").trim();
    return nuevaCadena;
  } else {
    return cadena;
  }
}

export const getMessageError = (errors, usuario) => {
  try {
    if (!errors) return null;
    const { graphQLErrors, networkError, message, funcion } = errors;
    let statusCode = "";
    let detalles = "";

    if (graphQLErrors && graphQLErrors.length) {
      const { extensions, message } = graphQLErrors[0];
      statusCode = extensions.code;
      detalles = message;
    }
    if (networkError) {
      const { result } = networkError;
      if (result && result.errors.length) {
        const { extensions, message } = result.errors[0];
        statusCode = extensions.code;
        detalles = message;
      } else {
        statusCode = networkError.statusCode;
        detalles = message;
      }
    }
    return {
      usuario,
      message,
      statusCode,
      detalles,
      funcion,
    };
  } catch (err) {
    console.log(err);
  }
};

export const isMobile = window.matchMedia("only screen and (max-width: 932px)")
  .matches; //768px
