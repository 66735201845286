import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Paper } from "@mui/material";
import { MainContext } from "../Context/MainCtx";

export default function FormatoCuotas({width = 200, small = false}) {
  const { formatoCuota, setFormatoCuota } = React.useContext(MainContext);

  const handleChange = (event) => {
    localStorage.setItem("momio_format", event.target.value);
    setFormatoCuota(event.target.value);
  };

  return (
    <Box width={width}>
      <FormControl fullWidth size="small" component={Paper}>
        <Select
          value={formatoCuota}
          onChange={handleChange}
          slotProps={{
            root: {
              sx: {
                height: small ? 30 : 40
              }
            },
          }}
        >
          <MenuItem value="Decimal">Decimal</MenuItem>
          <MenuItem value="Americano">Americano</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
